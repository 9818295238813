<template>
  <v-app>
    <v-banner class="lavBanner" single-line height="300" color="#F5F8FD">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/custometCaseBanner.png"
        height="100%"
        :transition="false"
        @load="handleLoad"
        class="d-flex align-center"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap align-center justify-center" style="color:#FFF">
              <!-- <div> -->
                <v-card-title class="font-size-48 white--text">
                  定制开发，更符合你的运营方案
                </v-card-title>
              </div>
            <!-- </div> -->
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-container class=" mt-10" >
        <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs new-tabs-two" >
          <v-tab v-for="(item,index) in data" :key="index">
            <v-card-title class="font-size-16" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.title }}</v-card-title>
          </v-tab>
        </v-tabs>
        <div class="new-two">
        <v-tabs-items v-model="tab" >
            <v-tab-item v-for="(item,index) in data" :key="index" class="mt-7" style="margin:0 auto;">
                <v-row v-show="tab === index" style="margin:0 auto;width:1120px">
                    <v-col v-for="(info,infoIndex) in item.tabItem" :key="infoIndex" cols="3">
                        <v-card
                            class="mx-auto item-card "
                            width="240"
                            height="410"
                            style="border-radius:unset;"
                            @click="goCaseDetail(info)"
                        >
                            <v-img
                                width="220"
                                height="340"
                                class="tran-sec"
                                :src="info.coverImg"
                                :aspect-ratio="9/16"
                            >
                            </v-img>
                            <div class="text-box">
                                <v-card-text class="font-size-14 sizeStyle" v-text="info.itemTitle"></v-card-text>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
        </div>
    </v-container>
  </v-app>
</template>

<script>
import lavData from '@/static/lavData.js';
import qs from 'qs'
let _this;
export default {
  name: 'customersCase',
  components: {
  },
  data() {
    return {
        imgLoad: false,
        data: lavData.customersCase,
        tab: null,
        showDialog: false
    }
  },
  created() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.showDialog = false;
    })
  },
  mounted(){
    // _this = this;
    // this.bannerLoad = true;
  },
  methods: {
    handleLoad: () => {
      _this.imgLoad = true;
    },
    handleBannerBtn(router) {
      if(router){
        window.open(window.location.protocol + "//" + window.location.host + router);
      }else {
        this.showDialog = true;
      }
    },
    goCaseDetail(key) {
      let query = {
        source: 'qita',
        anli: key.self,
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
::v-deep .theme--light.v-tabs-items{
     background: #f5f8fd !important; 
}
::v-deep .new-tabs-two{
  height: 78px !important;
  padding: 0 !important;
}
::v-deep .new-tabs-two .v-tabs-bar__content{
  padding: 0 !important;
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.04);
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
}
.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
